import React from "react";
import {TagControlled} from "./TagControlled";
import Style from "./index.module.css";

const TagsControlled = (props) => {
	const {value} = props;

	return (
		<div className={Style.tags}>
			Tags: {value.length ? value.map(tag => <TagControlled key={tag} value={tag} link={`/tag/${tag}`}/>) : `No tags`}
		</div>
	);
};

export {TagsControlled};
