import React, {useContext, useEffect, useRef} from "react";
import Style from "./index.module.css";
import {VideoContext} from "../VideoWithFrames/VideoContext";
import {FilterContext} from "./FilterContext";
import {IconButton} from "office-ui-fabric-react";
import {redraw, makeMonochrome} from "../Papermation/utils";
import {FramesContext} from "../Papermation/FramesContext";

const FrameItem = (props) => {
	const {time, canvas} = props;
	const ref = useRef(null);
	const {currentFrame, setCurrentFrame, size} = useContext(VideoContext);
	const {deleteFrame} = useContext(FramesContext);
	const {crop, filters} = useContext(FilterContext);

	useEffect(() => {
		const {current} = ref;
		if(!current.width || !current.height) {
			return;
		}
		redraw(canvas, current, crop);

		if (filters.monochrome) {
			makeMonochrome(current, filters.negative);
		}
	}, [canvas, crop, filters]);

	const active = time === currentFrame;

	return (
		<div className={`${Style.frame}${active ? ` ${Style.active}` : ``}`}>
			<canvas ref={ref} width={crop.w * size.width} height={crop.h * size.height} onClick={() => setCurrentFrame(time)}/>
			<IconButton onClick={() => deleteFrame(time)} iconProps={{iconName: "Delete"}} className={Style.frameDelete}/>
		</div>
	);
};

export {FrameItem};
