import React, {useContext} from "react";
import {Cropper, VideoContext, Frame} from "./";
import {Slider} from "office-ui-fabric-react";
import Style from "./index.module.css";
import {FramesContext} from "../Creator";

const VideoWithKeyframes = (props) => {
	const {src, onError} = props;

	const {setVideo, presentedFrames, currentFrame, setCurrentFrame} = useContext(VideoContext);
	const {frames} = useContext(FramesContext);

	return (
		<>
			<div className={Style.container}>
				<div className={Style.cropperContainer}>
					<video ref={setVideo} src={src} className={Style.video}
					       crossOrigin="" onError={onError} muted/>
					<Cropper/>
				</div>
			</div>
			<div>
				<Slider max={presentedFrames} value={currentFrame} onChange={setCurrentFrame} step={1}/>
				<div className={Style.keyframes}>
					{frames.map(([time]) => <Frame key={time} time={time}/>)}
				</div>
			</div>
		</>
	);
};

export {VideoWithKeyframes};
