import React, {useState, useEffect} from "react";
import Style from "../App/index.module.css";
import {FontIcon, Shimmer, ShimmerElementType} from "office-ui-fabric-react";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {ItemThumbnail} from "../Thumbnail";
import {FilterProvider} from "../Creator/FilterContext";

const Item = (props) => {
	const {item} = props;
	const [loaded, setLoaded] = useState(false);

	return (
		<div className={Style.card}>
			<Shimmer isDataLoaded={loaded} shimmerElements={[{type: ShimmerElementType.line, height: 150}]}>
				<NavLink to={`/${item._id}`}>
					<h3>{item.name}</h3>
					<ItemThumbnail item={item} setLoaded={setLoaded}/>
				</NavLink>
				<div>
					<FontIcon iconName="Print" className={Style.icon}/>
					{item.times_printed}
				</div>
			</Shimmer>
		</div>
	);
};

const List = (props) => {
	const [items, setItems] = useState(Array.from(Array(props.limit), (item, _id) => ({
		_id,
		urls: []
	})));

	useEffect(() => {
		(async () => {
			try {
				setItems((await axios.get("/", {params: props})).data);
			} catch(err) {
			}
		})();
	}, [props]);

	return (
		<div className={Style.cardContainer}>
			<FilterProvider>
				{items.length ? items.map(item => <Item key={item._id} item={item}/>) : "No items to show"}
			</FilterProvider>
		</div>
	);
};

export {List};
