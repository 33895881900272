import React, {useCallback} from "react";
import Style from "./index.module.css";
import {PrimaryButton} from "office-ui-fabric-react";

const FileInput = (props) => {
	const {id, onChange, text, children, className, ...otherProps} = props;

	const onFileChange = useCallback((event) => {
		onChange(event.target.files);
	}, [onChange]);

	return (
		<PrimaryButton className={Style.button}>
			<label className={Style.fileInputLabel} htmlFor={id}>
				{text || children}
			</label>
			<input type="file" onChange={onFileChange}
			       id={id} className={Style.fileInput} {...otherProps}/>
		</PrimaryButton>
	);
};

export {FileInput};
