import React, {useState, useEffect, useRef, useCallback} from "react";
import {drawDecoder, redraw} from "../Papermation/utils";
import Style from "../App/index.module.css";

const Thumbnail = (props) => {
	const {size, framesAmount, background, setLoaded, lineWidth, interval = 120} = props;

	const ref = useRef(null);
	const [frame, setFrame] = useState(null);

	const [decoder] = useState(document.createElement("canvas"));

	useEffect(() => {
		if(size && size.width && size.height && lineWidth) {
			Object.assign(decoder, {...size, width: size.width + framesAmount * lineWidth});
			drawDecoder(decoder, framesAmount, lineWidth);
		}
	}, [decoder, size, framesAmount, lineWidth]);

	const requestRef = useRef(null);
	useEffect(() => {
		if(size && size.width && size.height && decoder.width && decoder.height) {
			setLoaded && setLoaded(true);
			redraw(background, ref.current).drawImage(decoder, frame - framesAmount * lineWidth, 0);
		}
	}, [size, setLoaded, background, ref, frame, framesAmount, decoder, lineWidth]);

	const updateFrame = useCallback((now) => {
		setFrame(Math.floor(now / interval) % (framesAmount * lineWidth));
		requestRef.current = requestAnimationFrame(updateFrame);
	}, [setFrame, interval, framesAmount, lineWidth]);

	useEffect(() => {
		requestRef.current = requestAnimationFrame(updateFrame);
		return () => cancelAnimationFrame(requestRef.current);
	}, [requestRef, updateFrame]);

	return (
		<canvas ref={ref} {...size} className={Style.thumbnail}/>
	);
};

export {Thumbnail};
