import React, { useState } from "react";
import { Link } from "react-router-dom";
import Video from "./Video.mp4";
import Style from "./index.module.css";

const Intro = () => {
  const [size, setSize] = useState({ width: 854, height: 480 });

  return (
    <div className={Style.outer}>
      <div className={Style.container}>
        <div className={Style.overlay}>
          <div>
            <h3>Now you can print GIFs</h3>
            <Link to="/create">Create Your Own</Link>
          </div>
        </div>
        <video
          className={Style.video}
          {...size}
          onLoadedMetadata={() => setSize(null)}
          autoPlay
          loop
          muted
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export { Intro };
