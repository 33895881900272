import React from "react";
import {Header, List} from "../components/List";
import Style from "../components/App/index.module.css";
import {Helmet} from "react-helmet";

const All = () => {
	return (
		<section className={Style.content}>
			<Helmet>
				<title>Browse all - Papermate.io</title>
				<meta property="og:description" content="Animation that you can print on paper!"/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>
			<Header>All Papermations</Header>
			<List/>
		</section>
	);
};

export {All};
