import React, {useState, useEffect} from "react";
import Style from "./index.module.css";

const ppmm = 300/25.4;
const A4width = 180 * ppmm;
const lineWidth = .5 * ppmm;

const PrintContext = React.createContext(null);

const PrintProvider = (props) => {
	const [height, setHeight] = useState(80);
	const [prints, setPrints] = useState(null);

	useEffect(() => {
		if(prints) {
			window.print();
			setTimeout(() => setPrints(null), 500)
		}
	}, [prints]);

	return (
		<PrintContext.Provider value={{ppmm, lineWidth, A4width, setHeight, height, setPrints}}>
			<div className={Style.noPrint}>
				{props.children}
			</div>
			<div className={Style.printSection}>
				{prints}
			</div>
		</PrintContext.Provider>
	);
};

export {PrintContext, PrintProvider};
