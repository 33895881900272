const redraw = (source, destination, crop = {x: 0, y: 0, w: 1, h: 1}) => {
	if(destination.width && destination.height) {
		const context = destination.getContext("2d");
		context.clearRect(0, 0, destination.width, destination.height);
		context.drawImage(source, crop.x * source.width, crop.y * source.height, crop.w * source.width, crop.h * source.height,
			0, 0, destination.width, destination.height);
		return context;
	}
};

const makeMonochrome = (canvas, negative = false, threshold = 100) => {
	const context = canvas.getContext("2d");
	if(!canvas.width || !canvas.height) {
		return;
	}
	const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
	const {data} = imageData;

	for (let index = 0; index < data.length; index += 4) {
		const median = (data[index] + data[index + 1] + data[index + 2]) / 3;
		data[index] = data[index + 1] = data[index + 2] = (median >= threshold ^ negative) ? 255 : 0;
	}

	context.putImageData(imageData, 0, 0);
};

const drawBackground = (destination, canvasList, crop, filters, lineWidth = 3, threshold = 100) => {
	const {width, height} = destination;
	const context = destination.getContext("2d");
	const line = Math.round(lineWidth);

	canvasList.forEach((canvas, beginIndex) => {
		const croppedCanvas = document.createElement("canvas");
		Object.assign(croppedCanvas, {width, height});

		redraw(canvas, croppedCanvas, crop);
		if(filters.monochrome) {
			makeMonochrome(croppedCanvas, filters.negative, threshold);
		}

		for (let index = beginIndex * line; index < width; index += canvasList.length * line) {
			context.drawImage(croppedCanvas, index, 0, line, height, index, 0, line, height);
		}

		croppedCanvas.remove();
	});
};

const drawDecoder = (destination, framesAmount, lineWidth = 3) => {
	if(framesAmount <= 0) {
		return null;
	}
	const {width, height} = destination;
	const context = destination.getContext("2d");
	const line = Math.round(lineWidth);

	context.clearRect(0, 0, width, height);
	for (let index = 0; index < width; index += framesAmount * line) {
		context.fillRect(index, 0, (framesAmount - 1) * line, height);
	}
};

const fitToBounds = (original, bounds) => {
	let width = original.width;
	let height = original.height;
	if(width > bounds.width) {
		height /= width / bounds.width;
		width = bounds.width;
	}
	if(height > bounds.height) {
		width /= height / bounds.height;
		height = bounds.height;
	}
	return {width, height};
};

const createPreview = (canvasList, bounds, crop, filters, lineWidth = 3, threshold = 100) => {
	const {width, height} = canvasList[0];
	const destination = document.createElement("canvas");
	Object.assign(destination, fitToBounds({width: width * crop.w, height: height * crop.h}, {width: 350, height: 250}));
	drawBackground(destination, canvasList, crop, filters, lineWidth, threshold);
	return destination;
};

export {redraw, makeMonochrome, drawBackground, drawDecoder, fitToBounds, createPreview};
