import React from "react";
import Style from "../components/App/index.module.css";
import {Header, List} from "../components/List";
import {Helmet} from "react-helmet";

const Tag = (props) => {
	const {tag} = props.match.params;

	return (
		<section className={Style.content}>
			<Helmet>
				<title>Tagged {tag} - Papermate.io</title>
				<meta property="og:description" content="Animation that you can print on paper"/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>
			<Header>Tagged {tag}</Header>
			<List tags={tag}/>
		</section>
	);
};

export {Tag};
