import React, {useState, useCallback, useEffect} from "react";
import {TextField, DefaultButton, PrimaryButton, MessageBar, Modal, Spinner, MessageBarType} from "office-ui-fabric-react";
import axios from "axios";
import ReactGA from "react-ga";
import Style from "./index.module.css";

/* Polyfil for Object.fromEntries. See: so.com/questions/20059995 */
Object.fromEntries = Object.fromEntries || (arr => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) )));

const ms = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));

const ContactForm = (props) => {
	const {isOpen, onDismiss} = props;

	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);

	useEffect(() => {
		if(isOpen) {
			ReactGA.modalview("contact");
		}
	}, [isOpen]);

	const onSubmit = useCallback(async (event) => {
		event.preventDefault();
		const fd = new FormData(event.target);
		setLoading(true);
		try {
			await axios.post(
				"/",
				null,
				{
					baseURL: "",
					params: Object.fromEntries(fd)
				}
			);
			setSent(true);
			setLoading(false);
			await ms(3000);
			onDismiss();
			await ms(500);//TODO fix
			setSent(false);
		} catch(err) {
			setLoading(false);
		}
	}, [onDismiss]);

	return (
		<Modal isOpen={isOpen} onDismiss={onDismiss} containerClassName={Style.modal}>
			<DefaultButton text="Close" onClick={onDismiss} iconProps={{iconName: "Cancel"}}/>
			{!sent ? <>
				<form onSubmit={onSubmit} className={Style.form}>
					<input type="hidden" name="form-name" value="contact"/>
					<TextField name="name" label="Full Name" placeholder="First and Last" required disabled={loading}/>
					<TextField name="email" label="Email Address" placeholder="email@domain.tld" type="email" disabled={loading}/>
					<TextField name="message" label="Message" multiline required disabled={loading}/>
					<PrimaryButton type="submit" text="Submit" className={Style.submit} disabled={loading}/>
				</form>
				{loading && <Spinner/>}
			</>
			: <MessageBar messageBarType={MessageBarType.success}>
				Successfully sent!
			</MessageBar>}
		</Modal>
	);
};

export {ContactForm};
