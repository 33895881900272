import React, {useCallback, useRef, useState, useContext, useReducer, useEffect} from "react";
import Style from "./index.module.css";
import {FilterContext} from "../Creator/FilterContext";

const cursorMap = new Map([
	["1000", "ew-resize"],
	["0010", "ew-resize"],
	["0100", "ns-resize"],
	["0001", "ns-resize"],
	["1100", "nw-resize"],
	["0110", "ne-resize"],
	["0011", "se-resize"],
	["1001", "sw-resize"]
]);

const cursors = Array.from(cursorMap, ([key, cursor]) => ({
	key,
	cursor,
	mask: key.split('').map(parseFloat),
}));

const round = (precision) => (n) => Math.round(n * 10 ** precision) / 10 ** precision;

const clamp = (min, max) => (n) => Math.max(min, Math.min(n, max));

const reducer = (state, update) => ({...state, ...update});

const Handle = ({
	                cursor,
	                mask,
	                onPointerDown,
	                handleSize = 0.2,
                }) => {
	return (
		<rect
			className={Style.handle}
			onPointerDown={onPointerDown(mask)}
			width={1 - mask[0] - mask[2] + handleSize}
			height={1 - mask[1] - mask[3] + handleSize}
			x={mask[2] - handleSize / 2}
			y={mask[3] - handleSize / 2}
			style={{cursor}}
		/>
	);
};

const Cropper = () => {
	const {crop, setCrop} = useContext(FilterContext);
	const [curr, setCurr] = useReducer(reducer, {x0: crop.x, y0: crop.y, x1: crop.x + crop.w, y1: crop.y + crop.h});
	const [prev, setPrev] = useState(curr);

	const ref = useRef(null);
	const [pointerDownPosition, setPointerDownPosition] = useState(null);

	const onPointerDown = useCallback((mask) => (event) => {
		const rect = ref.current.getBoundingClientRect();
		setPointerDownPosition([event.clientX - rect.left, event.clientY - rect.top, mask]);
	}, [ref, setPointerDownPosition]);

	const onPointerUp = useCallback(() => {
		setPointerDownPosition(null);
		setPrev(curr);
	}, [setPointerDownPosition, setPrev, curr]);

	const onPointerMove = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();
		if (pointerDownPosition !== null) {
			const rect = ref.current.getBoundingClientRect();

			const dx = (event.clientX - rect.left - pointerDownPosition[0]) / rect.width;
			const dy = (event.clientY - rect.top - pointerDownPosition[1]) / rect.height;

			const [isx0, isy0, isx1, isy1] = pointerDownPosition[2];
			setCurr({
				x0: isx0 ? round(5)((prev.x1 - clamp(0, 1)(prev.x0 + dx)) > .2 ? clamp(0, 1)(prev.x0 + dx) : prev.x1 - .2) : prev.x0,
				y0: isy0 ? round(5)((prev.y1 - clamp(0, 1)(prev.y0 + dy)) > .2 ? clamp(0, 1)(prev.y0 + dy) : prev.y1 - .2) : prev.y0,
				x1: isx1 ? round(5)((clamp(0, 1)(prev.x1 + dx) - prev.x0) > .2 ? clamp(0, 1)(prev.x1 + dx) : prev.x0 + .2) : prev.x1,
				y1: isy1 ? round(5)((clamp(0, 1)(prev.y1 + dy) - prev.y0) > .2 ? clamp(0, 1)(prev.y1 + dy) : prev.y0 + .2) : prev.y1
			});
			setCrop({x: curr.x0, y: curr.y0, w: curr.x1 - curr.x0, h: curr.y1 - curr.y0});
		}
	}, [ref, pointerDownPosition, prev, curr, setCurr, setCrop]);

	const width = 3;
	const rect = ref.current ? ref.current.getBoundingClientRect() : {width, height: width};
	const line = {x: round(5)(width / rect.width), y: round(5)(width / rect.height)};

	useEffect(() => {
		const pointerUpEvents = ["pointerup", "pointerleave", "lostpointercapture"];
		document.body.addEventListener("pointermove", onPointerMove);
		pointerUpEvents.forEach(event => document.body.addEventListener(event, onPointerUp));
		return () => {
			document.body.removeEventListener("pointermove", onPointerMove);
			pointerUpEvents.forEach(event => document.body.removeEventListener(event, onPointerUp));
		}
	}, [onPointerMove, onPointerUp]);

	return (
		<svg ref={ref} className={`${Style.cropper}${pointerDownPosition ? ` ${Style.active}` : ``}`} viewBox="0 0 1 1" preserveAspectRatio="none" fill="rgba(0, 0, 0, .5)">
			<path d={`M0,0H1V1H0ZM${curr.x0},${curr.y0}V${curr.y1}H${curr.x1}V${curr.y0}Z`}/>
			<path d={`M${curr.x0 - line.x},${curr.y0 - line.y}H${curr.x1 + line.x}V${curr.y1 + line.y}H${curr.x0 - line.x}ZM${curr.x0},${curr.y0}V${curr.y1}H${curr.x1}V${curr.y0}Z`} fill="rgb(0, 120, 212)" className={Style.stroke}/>
			<svg className={Style.handles} viewBox="0 0 1 1" preserveAspectRatio="none" width={crop.w}
			     height={crop.h} x={crop.x} y={crop.y}>
				{cursors.map(props => <Handle onPointerDown={onPointerDown} {...props}/>)}
			</svg>
		</svg>
	);
};

export {Cropper};
