import React, {useEffect, useState, useCallback} from "react";

const VideoContext = React.createContext(null);

const VideoProvider = (props) => {
	const [video, setVideo] = useState(null);
	const [fps] = useState(7.5);
	const [presentedFrames, setPresentedFrames] = useState(0);
	const [currentFrame, _setCurrentFrame] = useState(-1);
	const [currentRenderedFrame, setCurrentRenderedFrame] = useState(-1);
	const [onError, setOnError] = useState(() => {});
	const [size, setSize] = useState({
		width: 0,
		height: 0
	});

	const setCurrentFrame = useCallback((frame) => {
		const actualFrame = Math.max(0, Math.min(presentedFrames, frame));
		video.currentTime = Math.round((actualFrame / fps) * 1000) / 1000;
		_setCurrentFrame(actualFrame);
	}, [video, presentedFrames, fps]);

	const onLoadedData = useCallback(() => {
		setPresentedFrames(Math.floor(video.duration * fps));
		if(!video.duration || !video.videoWidth || !video.videoHeight) {
			onError();
		}
		setSize({
			width: video.videoWidth,
			height: video.videoHeight
		});
		setCurrentFrame(0);
	}, [video, setPresentedFrames, setCurrentFrame, fps, onError]);

	const onSeeked = useCallback(() => {
		setCurrentRenderedFrame(Math.round(video.currentTime * fps));
	}, [video, setCurrentRenderedFrame, fps]);

	useEffect(() => {
		if(video) {
			video.addEventListener("loadeddata", onLoadedData);
			video.addEventListener("seeked", onSeeked);
			return () => {
				video.removeEventListener("loadeddata", onLoadedData);
				video.removeEventListener("seeked", onSeeked);
			};
		}
	}, [video, onLoadedData, onSeeked]);

	return (
		<VideoContext.Provider value={{setVideo, video, size, presentedFrames, currentFrame, setCurrentFrame, currentRenderedFrame, setOnError}}>
			{props.children}
		</VideoContext.Provider>
	);
};

export {VideoContext, VideoProvider};
