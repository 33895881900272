import React, {useCallback, useEffect, useState, useContext} from "react";
import Style from "../components/App/index.module.css";
import {PrintControls} from "../components/Print/PrintControls";
import axios from "axios";
import {FontIcon, Shimmer, ShimmerElementType, Link} from "office-ui-fabric-react";
import {TagsControlled} from "../components/Tag";
import {CaptchaContext} from "../components/Captcha/CaptchaContext";
import {NotFound} from "./NotFound";
import TimeAgo from "react-timeago";
import {ItemThumbnail} from "../components/Thumbnail";
import {FramesProvider} from "../components/Creator";
import {FilterProvider} from "../components/Creator/FilterContext";
import {VideoProvider} from "../components/VideoWithFrames";
import {Helmet} from "react-helmet";

const Item = (props) => {
	const {_id} = props.match.params;

	const [loaded, setLoaded] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const [item, setItem] = useState({
		name: null,
		src: null,
		thumbnail: null,
		urls: [],
		tags: [],
		featured: false,
		date_added: Date.now(),
		times_printed: 0
	});

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(`/${_id}`);

				setItem(response.data);
			} catch (err) {
				if (err.response && err.response.status === 404) {
					setNotFound(true);
				}
			}
		})();
	}, [_id, setNotFound]);

	const {execute} = useContext(CaptchaContext);
	const onPrint = useCallback(async (prints) => {
		if(prints.background) {
			setItem(item => ({...item, times_printed: item.times_printed + 1}));
			await axios.put(`/${item._id}`, {"g-recaptcha-response": await execute()});
		}
	}, [item._id, execute]);

	useEffect(() => {
		if(item._id) {
			window.prerenderReady = true;
		}
	}, [item._id]);

	if (notFound) {
		window.prerenderReady = true;
		return (
			<NotFound/>
		);
	}

	return (
		<section className={Style.content}>
			{item._id && <Helmet>
				<title>{item.name} - Papermate.io</title>
				<meta property="og:image" content={item.thumbnail}/>
				<meta property="og:description" content="Animation that you can print on paper!"/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>}
			<header className={Style.header}>
				<Shimmer isDataLoaded={loaded}>
					<h2>{item.name}</h2>
					<div>
						<FontIcon iconName="Calendar" className={Style.icon}/>
						<TimeAgo date={item.date_added}/>
					</div>
				</Shimmer>
			</header>
			<div className={Style.papermationCard}>
				<div className={Style.papermationCardPreview}>
					<Shimmer isDataLoaded={loaded} shimmerElements={[{type: ShimmerElementType.line, height: 150}]}>
						<FilterProvider>
							<ItemThumbnail item={item} setLoaded={setLoaded}/>
						</FilterProvider>
						<div>
							<FontIcon iconName="Print" className={Style.icon}/>
							Printed {item.times_printed} time{item.times_printed !== 1 && "s"}.
						</div>
						<TagsControlled value={item.tags}/>
						{item.src && <p>
							Source: <Link href={item.src} target="_blank">{item.src}</Link>
						</p>}
					</Shimmer>
				</div>
				<VideoProvider>
					<FramesProvider>
						<FilterProvider>
							<PrintControls item={item} onPrint={onPrint}/>
						</FilterProvider>
					</FramesProvider>
				</VideoProvider>
			</div>
		</section>
	);
};

export {Item};
