import React, {useRef, useCallback} from "react";
import ReCAPTCHA from "react-google-recaptcha";

const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

const CaptchaContext = React.createContext(null);

const CaptchaProvider = (props) => {
	const ref = useRef(null);

	const execute = useCallback(() => ref.current.execute(), [ref]);

	return (
		<CaptchaContext.Provider value={{execute}}>
			{props.children}
			<ReCAPTCHA ref={ref} sitekey={sitekey} size="invisible"/>
		</CaptchaContext.Provider>
	);
};

export {CaptchaContext, CaptchaProvider};
