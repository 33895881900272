import React from "react";
import {IconButton} from "office-ui-fabric-react";
import Style from "./index.module.css";

const TagUncontrolled = (props) => {
	const {value, onDelete} = props;

	return (
		<div className={Style.tag}>
			{value}
			<IconButton onClick={onDelete} iconProps={{iconName: "Delete"}}/>
			<input type="hidden" name="tags[]" value={value}/>
		</div>
	);
};

export {TagUncontrolled};
