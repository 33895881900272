import React from "react";
import Style from "../App/index.module.css";

const Header = (props) => {
	return (
		<header className={Style.header}>
			<h2>{props.children}</h2>
		</header>
	);
};

export {Header};
