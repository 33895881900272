import React from "react";
import {Header} from "../components/List";
import Style from "../components/App/index.module.css";
import {Helmet} from "react-helmet";

const videoID = "9fP9QIXJeXE";

const HowTo = () => {
	return (
		<section className={Style.content}>
			<Helmet>
				<title>How to - Papermate.io</title>
				<meta property="og:description" content="How to print a GIF or Video"/>
				<meta property="og:video" content={`https://youtu.be/${videoID}`}/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>
			<Header>How to print a GIF or Video</Header>
			<iframe width={640} height={360} src={`https://www.youtube-nocookie.com/embed/${videoID}`} frameBorder={0}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="How to video"/>
		</section>
	);
};

export {HowTo};
