import React, {useState, useEffect, useCallback, useContext} from "react";
import {Thumbnail} from "./index";
import {FilterContext} from "../Creator/FilterContext";

const ItemThumbnail = (props) => {
	const {item, setLoaded} = props;
	const {lineWidth} = useContext(FilterContext);

	const [size, setSize] = useState(null);

	const [background] = useState(new Image());
	const load = useCallback(() => {
		setSize({width: background.width, height: background.height});
	}, [background, setSize]);
	useEffect(() => {
		background.src = item.thumbnail;
		background.addEventListener("load", load);
		return () => background.removeEventListener("load", load);
	}, [background, item, load]);

	return (
		<Thumbnail size={size} framesAmount={item.urls.length} background={background} lineWidth={lineWidth} setLoaded={setLoaded}/>
	);
};

export {ItemThumbnail};
