import React, { useState } from "react";
import axios from "axios";
import { Switch, BrowserRouter, NavLink } from "react-router-dom";
import { TrackedRoute } from "../TrackedRoute";
import { Home, Create, Item, All, Tag, HowTo, NotFound } from "../../routes/";
import Style from "./index.module.css";
import {
  ActionButton,
  FontIcon,
  MessageBar,
  MessageBarType,
  Separator,
} from "office-ui-fabric-react";
import { initializeIcons } from "@uifabric/icons";
import { CaptchaProvider } from "../Captcha/CaptchaContext";
import { ContactForm } from "../ContactForm";
import ReactGA from "react-ga";
import { PrintProvider } from "../Print";
import styled from "styled-components";
import logo from "./logo.png";
initializeIcons();

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.mode = "no-cors";

ReactGA.initialize("UA-146852350-1");

const isActive = (match, location) =>
  !["create", "how-to"].includes(location.pathname.replace(/^\/|\/$/g, ""));

const Logo = styled.img`
  width: 142px;
  height: 40px;
`;

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BrowserRouter>
      <PrintProvider>
        <ContactForm isOpen={isOpen} onDismiss={() => setIsOpen(false)} />
        <header className={Style.navContainer}>
          <nav className={Style.nav}>
            <NavLink
              className={Style.navLink}
              activeClassName={Style.active}
              to="/"
              isActive={isActive}
            >
              <Logo src={logo} alt="papermate.io" />
            </NavLink>
            <NavLink
              className={Style.navLink}
              activeClassName={Style.active}
              to="/create/"
            >
              <FontIcon iconName="AddTo" className={Style.icon} />
              Create
            </NavLink>
            <NavLink
              className={Style.navLink}
              activeClassName={Style.active}
              to="/how-to/"
            >
              How to?
            </NavLink>
          </nav>
        </header>
        <MessageBar
          messageBarType={MessageBarType.warning}
          className={Style.contentChild}
          style={{ fontSize: "1.25rem" }}
        >
          A new version of papermate.io will be released soon!
        </MessageBar>
        <main className={Style.main}>
          <CaptchaProvider>
            <Switch>
              <TrackedRoute exact path="/" component={Home} />
              <TrackedRoute path="/create" component={Create} />
              <TrackedRoute path="/all" component={All} />
              <TrackedRoute path="/tag/:tag" component={Tag} />
              <TrackedRoute path="/how-to" component={HowTo} />
              <TrackedRoute path="/:_id" component={Item} />
              <TrackedRoute component={NotFound} />
            </Switch>
          </CaptchaProvider>
        </main>
        <footer className={Style.footer}>
          <div className={Style.content}>
            <ActionButton
              iconProps={{ iconName: "Mail" }}
              onClick={() => setIsOpen(true)}
              className={Style.contact}
            >
              Contact Us.
            </ActionButton>
            <Separator />
            <small>
              Copyright © 2021 Papermate.io. All rights reserved. Proudly made
              in Poland.
            </small>
          </div>
        </footer>
      </PrintProvider>
    </BrowserRouter>
  );
};

export { App };
