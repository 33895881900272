import React from "react";
import {NavLink} from "react-router-dom";
import Style from "./index.module.css";

const TagControlled = (props) => {
	const {value, link} = props;

	return (
		<NavLink to={link} className={Style.tag}>
			{value}
		</NavLink>
	);
};

export {TagControlled};
