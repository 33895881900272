import React, {useEffect, useCallback, useState} from "react";
import {Creator} from "../components/Creator/Creator";
import Style from "../components/App/index.module.css";
import {
	Separator,
	TextField,
	DefaultButton,
	Dialog,
	DialogFooter,
	PrimaryButton,
	ProgressIndicator
} from "office-ui-fabric-react";
import {FileInput} from "../components/FileInput";
import {FramesProvider} from "../components/Papermation/FramesContext";
import {VideoProvider} from "../components/VideoWithFrames";
import {FilterProvider} from "../components/Creator/FilterContext";
import {Helmet} from "react-helmet";
import axios from "axios";

const maxFileSizeInMB = 100;
const maxFileSizeInBytes = 100 * 1024**2;
const videoTypes = ["ogm", "wmv", "mpg", "webm", "ogv", "mov", "asx", "mpeg", "mp4", "m4v", "avi"];
const imageTypes = ["webp", "gif"];

const afterLast = (string, limiter) => string.slice(string.lastIndexOf(limiter) + 1);

const Create = () => {
	const [src, setSrc] = useState(null);
	const [error, setError] = useState(null);
	const [hiddenStartNew, setHiddenStartNew] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	const [progress, setProgress] = useState(null);

	const onError = useCallback((error) => {
		setError(error);
		setSrc(null);
	}, [setSrc]);

	const uploadToCloudinary = useCallback((file) => {
		const fd = new FormData();
		fd.append("file", file);
		fd.append("upload_preset", process.env.REACT_APP_FB_CLOUDINARY_UPLOAD_PRESET);
		setProgress(0);
		return axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_FB_CLOUDINARY_CLOUD_NAME}/auto/upload`, fd, {
			onUploadProgress: (event) => setProgress(.75 * event.loaded / event.total)
		});
	}, []);

	const onFileChange = useCallback(async (files) => {
		if (files) {
			const file = files[0];

			if(file.size > maxFileSizeInBytes) {
				onError({
					title: "Too large",
					subText: `Files over ${maxFileSizeInMB} MBs are currently unsupported.`
				});
			} else if(imageTypes.includes(afterLast(file.type, "/"))) {
				const {data} = await uploadToCloudinary(file);
				setSrc(data.secure_url);
			} else {
				setSrc(URL.createObjectURL(file));
			}
		}
	}, [onError, setSrc, uploadToCloudinary]);

	const onPaste = useCallback(async (event) => {
		const src = event.clipboardData.getData("text");
		if(src) {
			if([...imageTypes, ...videoTypes].includes(afterLast(src, "."))) {
				const {data} = await uploadToCloudinary(src);
				setSrc(data.secure_url);
			} else {
				setErrorMessage(`URL must be a direct link to motion image (.${imageTypes.join(", .")}) or video (.${videoTypes.join(", .")})`);
			}
		}
	}, [uploadToCloudinary]);

	const resetSrc = useCallback(() => {
		setSrc(null);
		setHiddenStartNew(true);
	}, [setSrc, setHiddenStartNew]);

	useEffect(() => {
		if(!src) {
			setErrorMessage(null);
		}
	}, [src]);

	return (
		<>
			<Helmet>
				<title>Create new - Papermate.io</title>
				<meta property="og:description" content="Create your own animation and print it."/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>
			<Dialog hidden={hiddenStartNew} dialogContentProps={{
				title: "Would you like to start new project?",
				subText: `Current progress will be lost`
			}} onDismiss={() => setHiddenStartNew(true)}>
				<DialogFooter>
					<PrimaryButton text="Cancel" onClick={() => setHiddenStartNew(true)}/>
					<DefaultButton text="Confirm" onClick={resetSrc}/>
				</DialogFooter>
			</Dialog>
			{<Dialog hidden={!error} dialogContentProps={error} onDismiss={() => setError(null)}>
				<DialogFooter>
					<PrimaryButton text="Cancel" onClick={() => setError(null)}/>
				</DialogFooter>
			</Dialog>}
			{!src ? <div className={Style.createNew}>
					{progress !== null && <ProgressIndicator percentComplete={progress}/>}
					<FileInput id="fileInput" accept={`video/*,.${imageTypes.join(",.")}`} onChange={onFileChange} disabled={progress !== null}>
						Add GIF or Video from disk
					</FileInput>
					<Separator>Or</Separator>
					<TextField placeholder="Paste URL to GIF or Video" onPaste={onPaste} errorMessage={errorMessage} disabled={progress !== null}/>
				</div>
				: <VideoProvider>
					<FramesProvider>
						<FilterProvider>
							<Creator src={src} onStartNewClick={() => setHiddenStartNew(false)} onError={onError}/>
						</FilterProvider>
					</FramesProvider>
				</VideoProvider>}
		</>
	);
};

export {Create};
