import React, {useState, useEffect, useContext, useMemo} from "react";
import {drawBackground, fitToBounds} from "../Papermation/utils";
import {VideoContext} from "../VideoWithFrames";
import {FramesContext} from "../Creator";
import {FilterContext} from "../Papermation";
import {Thumbnail} from "./index";

const bounds = {width: 640, height: 480};

const PreviewThumbnail = () => {
	const {size: {width, height}} = useContext(VideoContext);
	const {frames} = useContext(FramesContext);
	const {crop, filters, lineWidth} = useContext(FilterContext);
	const size = useMemo(() => fitToBounds({width: width * crop.w, height: height * crop.h}, bounds), [width, height, crop]);

	const [background] = useState(document.createElement("canvas"));
	useEffect(() => {
		Object.assign(background, size);
		const list = frames.map(([, canvas]) => canvas);
		drawBackground(background, list, crop, filters, lineWidth);
	}, [background, size, frames, crop, filters, lineWidth]);

	return (
		<Thumbnail size={size} framesAmount={frames.length} background={background} lineWidth={lineWidth}/>
	);
};

export {PreviewThumbnail};
