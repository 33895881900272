import React, {useCallback, useState, useContext} from "react";
import Style from "../App/index.module.css";
import {Redirect} from "react-router-dom";
import {TagsUncontrolled} from "../Tag";
import {PrimaryButton, TextField, Spinner} from "office-ui-fabric-react";
import {PrintControls} from "../Print/PrintControls";
import axios from "axios";
import {FramesContext} from "../Papermation/FramesContext";
import {CaptchaContext} from "../Captcha/CaptchaContext";
import {FilterContext, createPreview, redraw, makeMonochrome} from "../Papermation";
import {PreviewThumbnail} from "../Thumbnail";
import ReactGA from "react-ga";

const titleLength = 3;
const bounds = {width: 350, height: 250};

const CreateItem = () => {
	const {execute} = useContext(CaptchaContext);

	const [_id, set_id] = useState(null);

	const [isSaving, setIsSaving] = useState(false);

	const {frames} = useContext(FramesContext);
	const {crop, filters, lineWidth, threshold} = useContext(FilterContext);

	const [printed, setPrinted] = useState(0);

	const onSubmit = useCallback(async (event) => {
		event.preventDefault();
		const {name} = event.target.elements;
		if(name.value.length < titleLength) {
			name.focus();
			name.blur();
			return;
		}

		const fd = new FormData(event.target);

		setIsSaving(true);
		setTimeout(() => setIsSaving(false), 20000);

		const canvasList = frames.map(([, canvas]) => canvas);

		const preview = createPreview(canvasList, bounds, crop, filters, lineWidth, threshold);
		const previewBlob = await new Promise(resolve => preview.toBlob(resolve));
		fd.append("preview", previewBlob);

		const editedList = canvasList.map(canvas => {
			const {width, height} = canvas;
			const edited = document.createElement("canvas");
			Object.assign(edited, {width: width * crop.w, height: height * crop.h});
			redraw(canvas, edited, crop);
			if(filters.monochrome) {
				makeMonochrome(edited, filters.negative, threshold);
			}
			return edited;
		});
		const blobs = await Promise.all(editedList.map(canvas => new Promise(resolve => canvas.toBlob(resolve))));
		blobs.forEach(blob => fd.append("frames[]", blob));

		fd.append("g-recaptcha-response", await execute());

		try {
			const {data} = await axios.post("/", fd);
			set_id(data._id);
		} catch (err) {
			setIsSaving(false);
		}
	}, [setIsSaving, frames, execute, crop, filters, lineWidth, threshold]);

	const onGetErrorMessage = useCallback((value) => new Promise(resolve => {
		value.length < titleLength ? resolve(`Title needs to be at least ${titleLength} chars long.`) : resolve();
	}), []);

	const onPrint = useCallback(async (prints) => {
		if(prints.background) {
			ReactGA.event({category: "User", action: "Printed own project"});
			setPrinted(printed => printed + 1);
		}
	}, []);

	if (_id) {
		return (
			<Redirect to={`/${_id}`}/>
		);
	}

	return (
		<section className={Style.content}>
			<h2 className={Style.header}>Your Papermation</h2>
			<div className={Style.papermationCard}>
				<div className={Style.papermationCardPreview}>
					<PreviewThumbnail/>
					<form onSubmit={onSubmit}>
						<TextField name="name" label="Name" placeholder="Fancy name" validateOnLoad={false} validateOnFocusOut onGetErrorMessage={onGetErrorMessage} disabled={isSaving}/>
						<TagsUncontrolled disabled={isSaving}/>
						<TextField name="src" label="Source" placeholder="www.example.com" disabled={isSaving}/>
						<input name="times_printed" type="hidden" value={printed}/>
						<PrimaryButton type="submit" text="Save" disabled={isSaving}/>
						{isSaving && <Spinner />}
					</form>
				</div>
				<PrintControls onPrint={onPrint}/>
			</div>
		</section>
	);
};

export {CreateItem};
