import React, {useState, useCallback, useContext, useEffect} from "react";
import {VideoContext} from "../VideoWithFrames/VideoContext";
import {FilterContext} from "./FilterContext";
import {
	DefaultButton, Dialog, DialogFooter,
	PrimaryButton,
	Separator,
	Stack,
	Toggle
} from "office-ui-fabric-react";
import {VideoWithKeyframes} from "../VideoWithFrames/VideoWithFrames";
import {FramesList, FramesContext, CreateItem} from "./index";
import ReactGA from "react-ga";
import Style from "./index.module.css";

const Creator = (props) => {
	const {src, onStartNewClick, onError} = props;
	const {video, currentFrame, currentRenderedFrame, setOnError} = useContext(VideoContext);
	const {frames, hasFrame, addFrame, deleteFrame, maxFrames} = useContext(FramesContext);
	const {filters, setFilters} = useContext(FilterContext);
	const [exceed, setExceed] = useState(false);
	useEffect(() => {
		setOnError(() => () => onError({
			title: "There was an error!",
			subText: "Try with other file. H265 encoded videos may not be supported."
		}));
	}, [onError, setOnError]);

	const onToggleFrame = useCallback(() => {
		if (video && !hasFrame(currentRenderedFrame)) {
			if (frames.length >= maxFrames) {
				setExceed(true);
				return;
			}
			const canvas = document.createElement("canvas");
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			canvas.getContext("2d").drawImage(video, 0, 0);

			addFrame(currentRenderedFrame, canvas);
		} else {
			deleteFrame(currentRenderedFrame);
		}
	}, [video, frames, currentRenderedFrame, hasFrame, addFrame, deleteFrame, maxFrames]);

	const onCORSError = useCallback(() => {
		onError({
			title: "There was an error",
			subText: "Try to use another video."
		})
	}, [onError]);

	useEffect(() => ReactGA.event({category: "User", action: "Started own project"}), []);

	return (
		<>
			<Dialog hidden={!exceed} dialogContentProps={{
				title: "Reached maximum frames amount",
				subText: `Total of ${maxFrames} frames can be added. Remove some existing frames before adding new.`
			}} onDismiss={() => setExceed(false)}>
				<DialogFooter>
					<PrimaryButton text="Confirm" onClick={() => setExceed(false)}/>
				</DialogFooter>
			</Dialog>
			<div className={Style.videoEditor}>
				<VideoWithKeyframes src={src} onError={onCORSError}/>
				<Stack horizontal verticalAlign="center" tokens={{childrenGap: 15}}>
					<PrimaryButton onClick={onToggleFrame}
					                text={`${hasFrame(currentFrame) ? "Delete" : "Add"} frame`}/>
					<Separator vertical/>
					<Toggle checked={filters.monochrome} onChange={(event, value) => setFilters({monochrome: value})}
					        label="B&W"
					        onText="On" offText="Off"/>
					{filters.monochrome &&
					<Toggle checked={filters.negative} onChange={(event, value) => setFilters({negative: value})} label="Invert colors"
					        onText="On" offText="Off"/>}
					<DefaultButton text="Start new" onClick={onStartNewClick} className={Style.startNew}/>
				</Stack>
			</div>
			<FramesList/>
			{frames.length >= 2 && <CreateItem/>}
		</>
	);
};

export {Creator};
