import React, {useContext} from "react";
import {FrameItem} from "./index";
import Style from "./index.module.css";
import {FramesContext} from "../Papermation/FramesContext";

const FramesList = () => {
	const {frames} = useContext(FramesContext);

	return (
		<div className={Style.framesContainer}>
			<div className={Style.frames}>
				{frames.length >= 1 ? frames.map(([time, canvas]) => <FrameItem key={time} time={time} canvas={canvas}/>) : <p>Add frames</p>}
			</div>
		</div>
	);
};

export {FramesList};
