import React, { useEffect } from "react";
import { Header, List } from "../components/List";
import Style from "../components/App/index.module.css";
import { Link } from "react-router-dom";
import { ActionButton, FontIcon, Separator } from "office-ui-fabric-react";
import { Helmet } from "react-helmet";
import { Intro } from "../components/Intro";
import Video from "../components/Intro/Video.mp4";

const Home = () => {
  useEffect(() => {
    window.prerenderReady = true;
  }, []);

  return (
    <section className={Style.content}>
      <Helmet>
        <title>Papermate.io</title>
        <meta property="og:video" content={"https://papermate.io" + Video} />
        <meta
          property="og:description"
          content="Create your own animation and print it."
        />
        <meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID} />
      </Helmet>
      <Intro />
      <Separator />
      <Header>Featured Papermations</Header>
      <List featured limit={9} />
      <Link to="/all">
        <ActionButton className={Style.contentLink}>
          <FontIcon iconName="WaffleOffice365" className={Style.icon} />
          See more
        </ActionButton>
      </Link>
    </section>
  );
};

export { Home };
