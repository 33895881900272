import React from "react";
import {Header} from "../components/List";
import Style from "../components/App/index.module.css";
import {Helmet} from "react-helmet";

const NotFound = () => {
	return (
		<section className={Style.content}>
			<Helmet>
				<title>Page not found - Papermate.io</title>
				<meta property="og:description" content="Requested page couldn't be found"/>
				<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID}/>
			</Helmet>
			<Header>Not found</Header>
			The thing you're looking for couldn't be found.
		</section>
	);
};

export {NotFound};
