import React, {useCallback, useReducer, useMemo} from "react";

const FilterContext = React.createContext(null);

const lineWidth = 2;

const reducer = (state, update) => ({...state, ...update});

const initialCrop = {x: 0, y: 0, w: 1, h: 1};

const FilterProvider = (props) => {
	const [crop, setCrop] = useReducer(reducer, initialCrop);
	const cropped = useMemo(() => Object.entries(crop).some(([prop, value]) => initialCrop[prop] !== value), [crop]);
	const resetCrop = useCallback(() => setCrop(initialCrop), [setCrop]);
	const [filters, setFilters] = useReducer(reducer, {monochrome: false, negative: false});

	return (
		<FilterContext.Provider value={{crop, setCrop, cropped, resetCrop, filters, setFilters, lineWidth}}>
			{props.children}
		</FilterContext.Provider>
	);
};

export {FilterContext, FilterProvider};
