import React, {useEffect, useState, useCallback, useContext} from "react";
import options from "./allowedHeights";
import {
	DefaultButton,
	Dropdown,
	PrimaryButton,
	Separator,
	Shimmer,
	Label,
	ShimmerElementType, ShimmerElementsGroup
} from "office-ui-fabric-react";
import Style from "./index.module.css";
import {PrintContext, PrintableBackground, PrintableDecoder} from "./index";
import {FramesContext} from "../Creator";
import {FilterContext} from "../Creator/FilterContext";

const PrintControls = (props) => {
	const {onPrint, item} = props;
	const {frames, setFrames} = useContext(FramesContext);
	const filterContext = useContext(FilterContext);
	const {setPrints, setHeight, height} = useContext(PrintContext);

	const [loaded, setLoaded] = useState(false);

	const onChange = useCallback((event, item) => {
		setHeight(item.key);
	}, [setHeight]);

	const onPrintBackground = useCallback(() => {
		setPrints([<PrintableBackground key="background" frames={frames} filterContext={filterContext}/>]);
		onPrint && onPrint({background: true});
	}, [setPrints, onPrint, frames, filterContext]);
	const onPrintDecoder = useCallback(() => {
		setPrints([<PrintableDecoder key="decoder" frames={frames} filterContext={filterContext}/>]);
		onPrint && onPrint({decoder: true});
	}, [setPrints, onPrint, frames, filterContext]);
	const onPrintBoth = useCallback(() => {
		setPrints([<PrintableBackground key="background" frames={frames} filterContext={filterContext}/>, <PrintableDecoder key="decoder" frames={frames} filterContext={filterContext}/>]);
		onPrint && onPrint({decoder: true, background: true});
	}, [setPrints, onPrint, frames, filterContext]);

	useEffect(() => {
		if(!item) {
			setLoaded(true);
		} else if(item && item.urls.length) {
			let mounted = true;
			(async () => {
				const images = await Promise.all(item.urls.map(url => new Promise(resolve => {
					const image = new Image();
					image.addEventListener("load", () => resolve(image));
					image.src = url;
				})));
				if(mounted) {
					setFrames(images.map((image, i) => [i, image]));
					setLoaded(true);
				}
			})();
			return () => mounted = false;
		}
	}, [item, setFrames]);

	const shimmer = <>
		<ShimmerElementsGroup shimmerElements={[{type: ShimmerElementType.line, height: 120}]}/>
	</>;

	return (
		<div className={Style.controls}>
			<Dropdown options={options} selectedKey={height} onChange={onChange} label="Height:" className={Style.heights}/>
			<Shimmer isDataLoaded={loaded} customElementsGroup={shimmer}>
				<Label>Print:</Label>
				<div className={Style.buttonSeparate}>
					<PrimaryButton text="Papermation"
					               onClick={onPrintBackground}/>
					<PrimaryButton text="Decoder"
					               onClick={onPrintDecoder}/>
				</div>
				<Separator>Or</Separator>
				<DefaultButton text="Print both"
				               onClick={onPrintBoth} className={Style.buttonBoth}/>
				<p className={Style.tip}>If you printed Decoder with the same height ({height / 10} cm) and the same amount of frames ({frames.length}) then you can reuse it.</p>
			</Shimmer>
		</div>
	);
};

export {PrintControls};
