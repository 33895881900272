import React, {useCallback, useState} from "react";
import {TagUncontrolled} from "./index";
import {DefaultButton, Stack, TextField} from "office-ui-fabric-react";
import Style from "./index.module.css";

const TagsUncontrolled = (props) => {
	const {disabled} = props;
	const [tags, setTags] = useState([]);

	const [value, setValue] = useState("");

	const onChange = useCallback((event, value) => {
		setValue(value);
	}, [setValue]);

	const onAdd = useCallback(() => {
		const tag = value.trim().toLowerCase();
		if (tag && !tags.includes(tag)) {
			setTags([...tags, tag]);
		}
		setValue("");
	}, [tags, setTags, value]);

	const onKeyPress = useCallback((event) => {
		if (event.key === "Enter" || event.key === "Go") {
			event.preventDefault();
			onAdd();
		}
	}, [onAdd]);

	const onDelete = useCallback((deletedTag) => () => {
		setTags(tags.filter(tag => tag !== deletedTag));
	}, [setTags, tags]);

	return (
		<div>
			<div className={Style.tags}>
				{tags.map(tag => <TagUncontrolled key={tag} value={tag} onDelete={onDelete(tag)}/>)}
			</div>
			<Stack horizontal>
				<TextField placeholder="Add tag" value={value} onChange={onChange} onKeyPress={onKeyPress}
				           disabled={disabled}/>
				<DefaultButton text="Add" onClick={onAdd} disabled={disabled}/>
			</Stack>
		</div>
	);
};

export {TagsUncontrolled};
