import React, {useEffect, useRef, useContext} from "react";
import {PrintContext} from "./PrintContext";
import {drawDecoder} from "../Papermation/utils";
import Style from "./index.module.css";

const PrintableDecoder = (props) => {
	const {frames} = props;
	const {height: mmHeight, lineWidth, ppmm, A4width} = useContext(PrintContext);

	const ref = useRef(null);

	useEffect(() => {
		drawDecoder(ref.current, frames.length, lineWidth);
	}, [ref, frames, mmHeight, ppmm, lineWidth]);

	return (
		<canvas ref={ref} width={A4width} height={mmHeight * ppmm} className={Style.printable} style={{height: `${mmHeight}mm`}}/>
	);
};

export {PrintableDecoder};
