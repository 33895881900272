import React, {useEffect, useRef, useContext, useMemo} from "react";
import {PrintContext} from "./PrintContext";
import {drawBackground} from "../Papermation/utils";
import Style from "./index.module.css";

const PrintableBackground = (props) => {
	const {frames, filterContext: {crop, filters}} = props;
	const {height: mmHeight, lineWidth, ppmm} = useContext(PrintContext);

	const ref = useRef(null);

	const size = useMemo(() => {
		if(frames.length) {
			const frame = frames[0][1];
			const height = mmHeight * ppmm;
			return {
				width: height / (frame.height * crop.h) * (frame.width * crop.w),
				height
			}
		} else {
			return null;
		}
	}, [frames, crop, mmHeight, ppmm]);

	useEffect(() => {
		const canvasList = frames.map(([, canvas]) => canvas);
		drawBackground(ref.current, canvasList, crop, filters, lineWidth);
	}, [ref, frames, crop, filters, mmHeight, size, lineWidth]);

	return (
		<canvas ref={ref} {...size} className={`${Style.printable} ${Style.background}`} style={{height: `${mmHeight}mm`}}/>
	);
};

export {PrintableBackground};
