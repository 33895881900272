import React, {useState, useCallback} from "react";

const FramesContext = React.createContext(null);

const func = (a, b) => a[0] - b[0];

const maxFrames = 6;

const FramesProvider = (props) => {
	const [frames, setFrames] = useState([]);

	const hasFrame = useCallback((time) => {
		return frames.some((frame) => frame[0] === time);
	}, [frames]);
	const addFrame = useCallback((time, canvas) => {
		setFrames(frames => [...frames, [time, canvas]].sort(func));
	}, [setFrames]);
	const deleteFrame = useCallback((time) => {
		setFrames(frames => frames.filter((frame) => frame[0] !== time));
	}, [setFrames]);
	const resetFrames = useCallback(() => {
		setFrames([]);
	}, [setFrames]);

	return (
		<FramesContext.Provider value={{frames, setFrames, hasFrame, addFrame, deleteFrame, resetFrames, maxFrames}}>
			{props.children}
		</FramesContext.Provider>
	);
};

export {FramesContext, FramesProvider};
