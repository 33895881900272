import React, {useContext} from "react";
import {VideoContext} from "./";
import Style from "./index.module.css";

const Frame = (props) => {
	const {time} = props;
	const {presentedFrames, setCurrentFrame} = useContext(VideoContext);

	return (
		<span className={Style.keyframe}
		      style={{left: `${time / presentedFrames * 100}%`}}
		      onClick={() => setCurrentFrame(time)}/>
	);
};

export {Frame};
